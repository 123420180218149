<template lang="pug">
#preparacionRoot
	.cubreStatusBar.preparacion
	slot
</template>

<script lang="ts" setup>

onMounted(() => {
	consolo.log('Preparacion: onMounted')
})
</script>

<style lang="sass" scoped>
#preparacionRoot
	min-height: 100vh
	max-height: 100vh
	min-height: 100dvh
	max-height: 100dvh
	overflow-y: auto
</style>
